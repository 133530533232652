import React, { Component } from 'react';
import { connect } from 'react-redux';
import chunk from 'lodash/chunk';
import { Grid, Row, Col, Clearfix } from 'react-bootstrap';
import { Route } from 'react-router-dom';
import Currency from '../../common/Currency';
import { trackDownload } from '../../common/customga';
import Newsletter from '../../common/Newsletter';
import PsBlogPostListWithHeader from '../../common/PsBlogPostListWithHeader';
import PsDownloadButton from '../../common/PsDownloadButton';
import { PsLink } from '../../common/PsLink';
import PsProductListByCategory from '../../common/PsProductListByCategory';
import PsSocialButtons from '../../common/PsSocialButtons';
import PsStepVideo from '../../common/PsStepVideo';
import PsYoutube from '../../common/PsYoutube';
import { selectedLang } from '../../data/lang';
import Lang from '../../common/Lang';
import PsImage from '../../common/PsImage';
import PsMeta from '../../common/PsMeta';
import { addProduct } from '../../state/order/orderActions';
import VaseTipp from './VaseTipp';
import sfkduererhase from './../../data/products/sfkduererhase';

class BlogPost extends Component {
  componentDidMount() {
    if (window.PinUtils) {
      window.PinUtils.build();
    }
  }

  render() {
    const { post } = this.props;
    const imageFolder = 'content/' + post.imageFolder + '/';
    const addImageFolder = addFolder(imageFolder);
    return (
      <div
        className="content"
        id="content"
        itemScope
        itemType="http://schema.org/HowTo">
        <PsMeta
          title={post.meta_title[selectedLang]}
          description={post.meta_description_google && post.meta_description_google[selectedLang]}
          socialdescription={post.meta_description_social && post.meta_description_social[selectedLang]}
          socialimages={
            post.meta_images_social &&
            post.meta_images_social
              .map((image) => imageFolder + image[selectedLang])
              .concat(
                post.pinterest &&
                  post.pinterest.images &&
                  post.pinterest.images.map(
                    (image) => imageFolder + image.filename[selectedLang]
                  )
              )
          }
          type="article"
        />

        <div className="section grey-section">
          <Grid>
            <Row className="text-center">
              <Col smOffset={2} sm={8}>
                <Newsletter
                  text={
                    <Lang
                      de="Erhalte kostenlose DIY Vorlagen aus Papier und News zu unseren Produkten direkt in dein Postfach."
                      en="Get free templates and news about our products directly into your mailbox."
                    />
                  }
                  galabel="blogpost"
                />
              </Col>
            </Row>
          </Grid>
        </div>

        <Grid>
          <Row>
            <Col sm={6}>
              <PsImage
                image={addImageFolder(post.image_main)}
                itemProp="image"
              />
            </Col>
            <Col smOffset={0} sm={5}>
              <h1 itemProp="name">{post.h1[selectedLang]}</h1>
              {post.countermark && (
                <img
                  src={'https://' + post.countermark}
                  width="1"
                  height="1"
                  alt=""
                />
              )}
              <div
                itemProp="description"
                dangerouslySetInnerHTML={{
                  __html: post.description[selectedLang],
                }}
              />
            </Col>
          </Row>
        </Grid>

        <Grid>
          <Row>
            <Col sm={12}>
              <h2 style={{ textAlign: 'center' }}>
                {post.inspirationTitle[selectedLang]}
              </h2>
              {post.inspirationDescription && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: post.inspirationDescription[selectedLang],
                  }}
                />
              )}
            </Col>
          </Row>

          <Row>
            {post.inspirations &&
              chunk(post.inspirations, 2).map((chunkInsp, idx) => (
                <div key={idx}>
                  <Col sm={6}>
                    {chunkInsp.map(
                      (inspiration) =>
                        inspiration.image ? (
                          <PsImage
                            key={idx}
                            image={addImageFolder(inspiration.image)}
                          />
                        ) : inspiration.text[selectedLang] ? (
                          <p
                            key={idx + 'b'}
                            dangerouslySetInnerHTML={{
                              __html: inspiration.text[selectedLang],
                            }}
                          />
                        ) : null
                    )}
                  </Col>
                  {(idx + 1) % 2 === 0 && <Clearfix />}
                </div>
              ))}
            {post.pinterest &&
              post.pinterest.images.reverse().map((img) => (
                <Col sm={6} key={img.filename[selectedLang]}>
                  <PsImage image={addImageFolder(img)} />
                </Col>
              ))}
          </Row>
        </Grid>

        <div
          style={{
            backgroundColor: post.stepsBackgroundColor,
            paddingBottom: '30px',
          }}>
          <Grid>
            <div
              itemProp="step"
              itemScope
              itemType="http://schema.org/HowToSection">
              {post.stepsTitle && (
                <h2 itemProp="name" className="text-center">
                  {post.stepsTitle[selectedLang]}
                </h2>
              )}

              <Row>
                <Col smOffset={3} sm={6}>
                  <p
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: '18px',
                      marginBottom: '12px',
                    }}>
                    <Lang de="Materialien" en="Materials needed" />
                  </p>
                </Col>
                <Col smOffset={3} sm={6}>
                  <ul>
                    {post.materials.map((material) => (
                      <li
                        key={
                          material[selectedLang] || material.name[selectedLang]
                        }
                        itemProp={material.type || 'tool'}
                        itemScope
                        itemType="http://schema.org/HowToItem">
                        <span itemProp="name">
                          {material[selectedLang] ||
                            material.name[selectedLang]}
                        </span>
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>

              <Row>
                {post.steps &&
                  post.steps.map((step, index) => {
                    return (
                      <div key={index}>
                        <Col
                          smOffset={step.image && !step.video ? 0 : 4}
                          sm={step.image && !step.video ? 4 : 4}>
                          <div
                            itemProp="itemListElement"
                            itemScope
                            itemType="http://schema.org/HowToStep">
                            <div
                              itemProp="itemListElement"
                              itemScope
                              itemType="http://schema.org/HowToDirection">
                              <span itemProp="duringMedia">
                                {step.image &&
                                  !step.video && (
                                    <PsImage
                                      image={addImageFolder(step.image)}
                                      data-pin-nopin
                                      itemProp="image"
                                    />
                                  )}
                                {step.video && (
                                  <PsStepVideo
                                    image={
                                      process.env.PUBLIC_URL +
                                      '/images/' +
                                      addImageFolder(step.image).filename[
                                        selectedLang
                                      ]
                                    }
                                    video={
                                      process.env.PUBLIC_URL +
                                      '/images/' +
                                      imageFolder +
                                      step.video
                                    }
                                  />
                                )}
                              </span>
                              <p>
                                <span className="highlight-font">
                                  {++index}.{' '}
                                </span>
                                <span
                                  itemProp="text"
                                  dangerouslySetInnerHTML={{
                                    __html: step.text[selectedLang],
                                  }}
                                />
                              </p>
                            </div>
                          </div>
                        </Col>
                        {step.image && !step.video ? (
                          index % 3 === 0 && <Clearfix />
                        ) : (
                          <Clearfix />
                        )}
                      </div>
                    );
                  })}
              </Row>

              <Route
                path="/:lang(de|en)/blog/diy-vase"
                component={VaseTipp}
              />
            </div>
          </Grid>
        </div>

        {post.video && (
          <Grid>
            <Row>
              <Col sm={12}>
                <h2>{post.video.title[selectedLang]}</h2>
              </Col>
              <Col smOffset={0} sm={12}>
                <p>{post.video.description[selectedLang]}</p>
                <PsYoutube
                  title={post.video.caption[selectedLang]}
                  videoid={post.video.videoid}
                />
              </Col>
            </Row>
          </Grid>
        )}
        {post.url !== '3d-papier-osterhasen-basteln' ? (
          <Grid>
            <Row>
              {post.pdf && post.id ? (
                <Col smOffset={3} sm={6}>
                  <div
                    style={{
                      textAlign: 'center',
                      marginTop: '30px',
                    }}>
                    <Lang
                      de="Digitale Vorlage zum Herunterladen als PDF"
                      en="Digital template as pdf"
                    />
                  </div>
                  <div
                    style={{
                      fontSize: 26,
                      fontFamily: '"Montserrat"',
                      letterSpacing: 2,
                      textAlign: 'center',
                    }}
                    itemProp="offers"
                    itemScope
                    itemType="http://schema.org/Offer">
                    <Currency value={post.pdf.price} />
                  </div>
                  <div
                    style={{
                      lineHeight: 1,
                      textAlign: 'center',
                      marginBottom: '20px',
                    }}>
                    <small
                      style={{
                        fontWeight: 'normal',
                      }}>
                      <Lang de="inkl. MwSt." en="incl. VAT" />
                    </small>
                  </div>
                  <div
                    className="downloadbox"
                    style={{ minWidth: 240, marginBottom: '20px' }}>
                    <button
                      type="submit"
                      id="btnJetztKaufen"
                      className="btn btn-primary btn-lg btn-block jetzt-kaufen"
                      onClick={() => buyNow(this.props)}>
                      <Lang
                        de="Vorlage in den Warenkorb legen"
                        en="Add to bag"
                      />
                    </button>
                  </div>
                </Col>
              ) : (
                post.downloadName && (
                  <Col smOffset={3} sm={6}>
                    <PsDownloadButton name={post.downloadName} />
                  </Col>
                )
              )}
            </Row>
          </Grid>
        ) : (
          <Grid>
            <Row>
              <div className="pricing-background">
                <div className="">
                  <div className="pricing-table">
                    <div className="pricing-plan is-featured">
                      {/*<PsImage*/}
                      {/*image={addImageFolder(post.image_main)}*/}
                      {/*itemProp="image"*/}
                      {/*className="pricing-img"*/}
                      {/*/>*/}
                      <h2 className="pricing-header">
                        <Lang
                          de="Digitale Vorlage"
                          en="Digital template as pdf"
                        />
                      </h2>
                      <ul className="pricing-features">
                        <li className="pricing-features-item">
                          <Lang
                            de="PDF Vorlage zum Herunterladen"
                            en="Digital template as pdf"
                          />
                        </li>
                        <li className="pricing-features-item">
                          <Lang
                            de="Bastel so viele Osterhasen wie du möchtest"
                            en="craft as many bunnies as you wish"
                          />
                        </li>
                        <li className="pricing-features-item">
                          <Lang
                            de="gestalte deinen einzigartigen Hasen"
                            en="make your own unique Easter bunny"
                          />
                        </li>
                      </ul>
                      <span className="pricing-price">
                        <Currency value={post.pdf.price} />
                      </span>
                      {/*className="btn btn-primary btn-lg jetzt-kaufen"*/}
                      <a
                        onClick={() => buyNow(this.props)}
                        className="pricing-button">
                        <Lang de="zum Download" en="Add to bag" />
                      </a>
                    </div>

                    <div className="pricing-plan">
                      {/*<PsImage*/}
                      {/*image={sfkduererhase.colorinspirations[0].image}*/}
                      {/*className="pricing-img"*/}
                      {/*data-pin-nopin="true"*/}
                      {/*/>*/}
                      <h2 className="pricing-header">
                        <Lang de="DIY Kit" en="DIY Kit" />
                      </h2>
                      <ul className="pricing-features">
                        <li className="pricing-features-item">
                          <Lang
                            de="bedruckte DIN A4 Bögen Fotokarton"
                            en="printed DIN A4 sheets of paper"
                          />
                        </li>
                        <li className="pricing-features-item">
                          <Lang
                            de="Doppelseitige Klebepads"
                            en="Double-sided adhesive pads"
                          />
                        </li>
                        <li className="pricing-features-item">
                          <Lang
                            de="Schritt-für-Schritt Anleitung"
                            en="step by step instructions"
                          />
                        </li>
                      </ul>
                      <span className="pricing-price">
                        <Currency value={sfkduererhase.choose.price} />
                      </span>
                      <PsLink
                        to={'/product/' + sfkduererhase.id}
                        className="pricing-button">
                        <Lang de="zum DIY Kit" en="Add to bag" />
                      </PsLink>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </Grid>
        )}

        <PsBlogPostListWithHeader />
        <PsProductListByCategory />

        <PsSocialButtons post={post} />
      </div>
    );
  }
}

export default connect()(BlogPost);

export function addFolder(imageFolder) {
  return (image) => ({
    ...image,
    filename: {
      de: imageFolder + image.filename.de,
      en: imageFolder + image.filename.en,
    },
  });
}

function buyNow(props) {
  trackDownload(props.post.downloadName, 'cart')();
  props.dispatch(addProduct(props.post.id, null, null, true, true));
  props.history.push('/' + props.match.params.lang + '/cart/');
}
