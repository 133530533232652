import Raven from 'raven-js';
import { trackAddToCart, trackPurchase } from '../../common/customga';
import { getTextBySelectedLang, selectedLang } from '../../data/lang';
import { validationError } from '../fetch/fetchActions';
import { getVoucherMessage } from '../fetch/fetchSelectors';
import { validateVoucher } from '../voucher/voucherActions';
import {
  getDeliveryTime,
  getOrderProductEnhanced,
  getOrderProductsEnhanced,
  getOrderSubtotalSumWithVoucher,
  getOrderSumGross,
  getProductVariantByIdAndVariantKey,
  getShippingCosts,
  getVatAmount,
  getVatPercent,
  getVoucherAmount,
  getVoucherCode,
} from './orderSelectors';
import { getVoucher } from '../voucher/voucherSelectors';

export const ADD_PRODUCT = 'ADD_PRODUCT';
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
export const ORDER_SUCCESSFUL = 'ORDER_SUCCESSFUL';
export const SAVE_ORDER_NUMBER = 'SAVE_ORDER_NUMBER';

export function addProduct(
  productId,
  color1,
  color2,
  isDigitalProduct
) {
  return (dispatch, getState) => {
    const productVariant = getProductVariantByIdAndVariantKey(getState(), {
      productId: productId,
      isDigitalProduct,
    });
    if (!productVariant) {
      console.error(
        `productVariant with productId [${productId}] and isDigitalProduct [${isDigitalProduct}] does not exist.`
      );
      Raven.captureMessage(
        `productVariant with productId [${productId}] and isDigitalProduct [${isDigitalProduct}] does not exist.`
      );
      return;
    }

    const orderProductEnhanced = getOrderProductEnhanced(getState(), {
      id: productId,
      color1,
      color2,
      isDigitalProduct,
      quantity: 1,
    });
    trackAddToCart('add', orderProductEnhanced);

    return dispatch({
      type: ADD_PRODUCT,
      payload: {
        productId,
        variantKey: orderProductEnhanced.variantKey,
        color1,
        color2,
        isDigitalProduct,
      },
    });
  };
}

export function removeProduct(
  productId,
  variantKey,
  color1,
  color2,
  isDigitalProduct
) {
  return (dispatch, getState) => {
    const orderProductEnhanced = getOrderProductEnhanced(getState(), {
      id: productId,
      variantKey,
      color1,
      color2,
      isDigitalProduct,
      quantity: 1,
    });
    trackAddToCart('remove', orderProductEnhanced);
    return dispatch({
      type: REMOVE_PRODUCT,
      payload: {
        productId,
        variantKey: orderProductEnhanced.variantKey,
        isDigitalProduct,
      },
    });
  };
}

export function orderSuccessful(orderNumber, order) {
  return {
    type: ORDER_SUCCESSFUL,
    payload: {
      orderNumber,
      order,
    },
  };
}

export function saveOrderNumber(orderNumber) {
  return {
    type: SAVE_ORDER_NUMBER,
    payload: {
      orderNumber,
    },
  };
}

export function sendOrder(formValues) {
  return async (dispatch, getState) => {
    console.info('submitted: ', formValues);

    await dispatch(validateVoucher());
    const voucherMessage = getVoucherMessage(getState());
    if (voucherMessage && voucherMessage.error) {
      console.log('voucherMessage is set: ' + voucherMessage.error);
      return Promise.reject();
    }

    const state = getState();
    const orderProductsEnhanced = getOrderProductsEnhanced(state);
    if (!orderProductsEnhanced || orderProductsEnhanced.length === 0) {
      console.log('order does not contain orderProductsEnhanced');
      await dispatch(
        validationError(
          getTextBySelectedLang(
            'Es befinden sich keine Produkte im Warenkorb. Klicke auf das Papershape Logo um Produkte hinzuzufügen.',
            'The cart is emtpy. Click on the papershape logo in order to add products.'
          )
        )
      );
      return Promise.reject();
    }
    const orderSumGross = getOrderSumGross(state);
    const shippingCosts = getShippingCosts(state);
    const billingAddress = {
      firstname: formValues.name,
      lastname: formValues.surname,
      streetNameNumber: formValues.billingAddress.streetNameNumber,
      additional: formValues.billingAddress.additional,
      zipCode: formValues.billingAddress.zipCode,
      city: formValues.billingAddress.city,
      country: formValues.billingAddress.country,
    };
    const voucherAmount = getVoucherAmount(state);
    const body = {
      type: 'CreateOrder',
      payload: {
        email: formValues.email,
        billingAddress: billingAddress,
        shippingAddress: formValues.alternativeShippingAddress
          ? {
              firstname: formValues.shippingAddress.name,
              lastname: formValues.shippingAddress.surname,
              streetNameNumber: formValues.shippingAddress.streetNameNumber,
              additional: formValues.shippingAddress.additional,
              zipCode: formValues.shippingAddress.zipCode,
              city: formValues.shippingAddress.city,
              country: formValues.shippingAddress.country,
            }
          : billingAddress,
        note: formValues.note,
        deliveryTime: getDeliveryTime(state),
        newsletter: formValues.newsletter || false,
        orderLanguage: selectedLang,
        voucher: voucherAmount ? getVoucher(state) : undefined,
        voucherAmount: voucherAmount || undefined,
        orderSubtotalSumTotalGross: getOrderSubtotalSumWithVoucher(state),
        shippingCostsGross: shippingCosts,
        orderSumGross: orderSumGross,
        vatPercent: getVatPercent(state),
        products: orderProductsEnhanced.map((product) => ({
          id: product.id,
          sku: product.sku,
          name: product.name[selectedLang],
          variantName: product.isDigitalProduct
            ? 'download'
            : product.variantName[selectedLang],
          category: product.category,
          quantity: product.quantity,
          pricePerUnitGross: product.pricePerUnit,
          isDigitalProduct: product.isDigitalProduct,
        })),
      },
    };

    return dispatch({
      fetch: {
        body,
      },
    }).then(({ orderNumber, order }) => {
      dispatch(orderSuccessful(orderNumber, order));

      trackPurchase(
        getOrderProductsEnhanced(state),
        orderNumber,
        orderSumGross,
        getVatAmount(state),
        shippingCosts,
        getVoucherCode(state)
      );
    });
  };
}
