import React from 'react';
import { connect } from 'react-redux';
import { selectedLang } from '../data/lang';
import Currency from '../common/Currency';
import { addProduct, removeProduct } from '../state/order/orderActions';
import cn from 'classnames';
import PsImage from './PsImage';
import Lang from '../common/Lang';

const ProductRow = ({ product, dispatch, readonly = false }) => (
  <div>
    <div className="row form-horizontal">
      <div>
        <div>
          <div className="col-xs-5 col-sm-4 col-md-2">
            <PsImage image={product.image} className="img-home-portfolio" />
          </div>
          <div className="col-xs-7 col-sm-8 col-md-4">
            <p className="form-control-static">
              {product.name[selectedLang]}{' '}
              {product.variantName[selectedLang]
                ? `(${product.variantName[selectedLang]})`
                : ''}
            </p>
            <br />
            {product.isDigitalProduct ? (
              <ul>
                <li>
                  <Lang
                    de="Digitale Vorlage zum Herunterladen als PDF"
                    en="Digital PDF template for downloading"
                  />
                </li>
                <li>
                  <Lang
                    de="Schritt-für-Schritt Anleitung (inkl. Video)"
                    en="step by step instructions (incl. video tutorial)"
                  />
                </li>
              </ul>
            ) : (
              <ul>
                <li>
                  <Lang
                    de="bedruckte DIN A4 Bögen Fotokarton"
                    en="printed DIN A4 sheets of paper"
                  />
                </li>
                <li>
                  <Lang
                    de="Schritt-für-Schritt Anleitung (inkl. Video)"
                    en="step by step instructions (incl. video tutorial)"
                  />
                </li>
              </ul>
            )}
          </div>
        </div>

        <div className="clearfix visible-xs" />

        <div className="col-xs-6 col-sm-8 col-md-6">
          <div className="form-group">
            <label
              style={!readonly ? { padding: '19px' } : {}}
              className={cn('col-xs-12 col-sm-6 control-label', {
                anzahl: !readonly,
              })}>
              <Lang de="Anzahl" en="Amount" />
            </label>
            <div className="col-xs-12 col-sm-6 form-control-static">
              <span style={{ padding: 5 }}>{product.quantity}</span>
              {!readonly &&
                !product.isDigitalProduct && (
                  <a
                    className="btn btn-default btn-lg"
                    onClick={() => {
                      dispatch(
                        addProduct(
                          product.id,
                          product.variantKey,
                          product.color1,
                          product.color2,
                          product.isDigitalProduct
                        )
                      );
                    }}>
                    +
                  </a>
                )}
              {!readonly && (
                <a
                  className="btn btn-default btn-lg"
                  onClick={() => {
                    dispatch(
                      removeProduct(
                        product.id,
                        product.variantKey,
                        product.color1,
                        product.color2,
                        product.isDigitalProduct
                      )
                    );
                  }}>
                  -
                </a>
              )}
            </div>
          </div>
        </div>

        <div className="col-xs-6 col-sm-8 col-md-6">
          <div className="form-group">
            <label className="col-xs-12 col-sm-6 control-label">
              <Lang de="Einzelpreis" en="Unit price" />
            </label>
            <div className="col-xs-12 col-sm-6 form-control-static">
              <Currency value={product.pricePerUnit} />
            </div>
          </div>
        </div>

        <div className="col-xs-6 col-xs-offset-6 col-sm-8 col-sm-offset-0 col-md-6">
          <div className="form-group">
            <label className="col-xs-12 col-sm-6 control-label">
              <Lang de="Zwischensumme" en="Subtotal" />
            </label>
            <div className="col-xs-12 col-sm-6 form-control-static">
              <Currency value={product.subTotal} />
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />
  </div>
);

export default connect()(ProductRow);
