export default {
  url: '3d-schneeflocke-basteln',
  countermark: 'vg07.met.vgwort.de/na/f7837f0c09214675b739da274981770d',
  imageFolder: 'schneeflocke',

  name: {
    de: 'Schneeflocke',
    en: 'Snowflake',
  },
  id: '3-28-blog',
  pdf: {
    price: 4.9,
    priceThirdCountry: 4.9,
    image: {
      filename: {
        de: 'content/schneeflocke/3d-schneeflocke-basteln-papier.jpg',
        en: 'content/schneeflocke/3d-schneeflocke-basteln-papier.jpg',
      },
      alt_text: {
        de: 'DIY Schneeflocke basteln aus Papier ',
        en: 'Learn how to make a papercraft snow flakes ',
      },
    },
  },

  meta_title: {
    de: '3D Schneeflocke mit Papier basteln',
    en: 'How to make 3d snowflake from paper ',
  },
  meta_description_google: {
    de: `Die bekannten Scherenschnitte für wunderschöne Schneeflocken kennen wir alle. In dieser Anleitung zeige ich euch, wie ihr aus der 
      einfachen Vorlage einen 3D Eiskristall zabuert, der als Fensterdeko aufgehängt oder als Tischdeko dekoriert werden kann. `,
    en: `Decorate your home with these adorable 3d snowflakes from paper. With the template from papershape it is super easy and a lovely winter decor for 
      your windows.`,
  },
  meta_description_social: {
    de: '',
    en: '',
  },
  meta_images_social: [
    {
      de: '3d-schneeflocke-basteln-papier.jpg',
      en: '3d-snowflake-paper-craft.jpg',
    },
  ],
  image_main: {
    filename: {
      de: '3d-schneeflocke-basteln-papier.jpg',
      en: '3d-snowflake-paper-craft.jpg',
    },
    alt_text: {
      de: '3d Schneeflocke basteln aus Papier. Kein Scherenschnitt. Kostenlose Vorlage von #papershape',
      en: '3d snowflake from paper - no silhouette. free template from #papershape ',
    },
  },
  h1: {
    de: '3D Schneeflocken aus Papier basteln',
    en: 'How to make 3d snowflakes from paper',
  },
  description: {
    de: `
      <p>Wenn die kalte Jahreszeit Einzug hält und es schön winterlich kalt wird, ist es an der Zeit Schneeflocken aus Papier zu basteln. Für Schneeflocken im Scherenschnitt gibt es unzählige schöne Varianten.
      Schaut euch dazu mal bei Pinterest um.</p>
     <p>In diesem DIY Post zeige ich euch aber, wie ihr eine 3D Schneeflocke aus Papier im Kristalllook selber bastelt. Egal, ob ihr diese Schneeflocken am Weihnachtsbaum aufhängt, als winterliche Tischdeko arrangiert oder sie ins vereiste Fenster hängt, das Basteln ist der größte Spass daran. </p>
      
      <p>Eine weitere DIY Winteridee ist der <a href="/de/blog/3d-stern-falten/">riesige 3D Stern a là Bascetta</a>, den ihr aber nicht falten und stecken müsst. </p>
  
     
      Übrigens habe ich diese Schneeflocken im <a href="https://www.swr.de/buffet/kreativ/eiskristall-aus-papier/-/id=257204/did=18254772/nid=257204/198k6xx/index.html" target="_blank">ARD-Buffet</a>
      vorgestellt und meinen Live-Auftritt könnt ihr unten im Video ansehen.
       
    
    `,
    en: `
      <p>
      Snowflakes in winter are such a lovely window decor.
      </p>
          <p>
     Creating flat snowflakes is fun and gives a lot of variations. But why not trying a 3d snowflake?</p>
     
     <p>In this tutorial you learn how to make such a cool snowflake from paper.
     And if you want to add a 3d star to your winter decor, then you must try this
      <a href="/en/blog/3d-stern-falten/">huge 3d paper star</a>, too. How about decorate your flat for fall with paper diys this year?
      </p>
    `,
  },
  inspirationTitle: {
    de: '',
    en: '',
  },
  inspirations: [],

  stepsTitle: {
    de: 'Wie bastel ich 3D Schneeflocken aus Papier - Anleitung',
    en: 'How do I make 3d snowflakes from paper',
  },
  steps: [
    {
      image: {
        filename: {
          de: '01_schneiden.jpg',
          en: '01_schneiden.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Druckt das Template aus und schneidet die Papierteile entlang der durchgezogenen Linien aus. 
        Legt ein Lineal an die gestrichelten Linien an und falzt sie vor, indem ihr mit einer Ahle z.B. an ihnen entlang fahrt. 
       
        `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '02_falten.jpg',
          en: '02_falten.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: `Faltet dann alle gestrichelten Linien je nach Markierung nach innen (kurze Striche) oder nach außen (lange Striche). `,
        en: ``,
      },
    },
    {
      image: {
        filename: {
          de: '03_kleben.jpg',
          en: '03_kleben.jpg',
        },
        alt_text: {
          de: '',
          en: '',
        },
      },

      text: {
        de: ` 	Klebt auf die unbedruckte Seite der Laschen so viele Klebepads wie angegeben. 
        Die Laschen erkennt ihr am Symbol für die Anzahl der Klebepads.
         Alle überstehenden Klebepads müssen abgeschnitten werden. Ihr könnt bei Papier bis zu 160g auch normalen Klebstoff verwenden. 
          Die Laschen werden nun in nummerischer Reihenfolge (1,2,3) verklebt. Beginnt mit Lasche Nr. 1 (Start) und verklebt
           diese durch festes Zusammendrücken mit der zugehörigen Fläche 1 auf Papierteil A.
            Schaut beim Verkleben der Laschen von außen auf das Modell. 
        	Das letzte Element der Schneeflocke kann einfach gesteckt werden. 
        	Nun die Schneeflocke entweder an die Wand an einem Faden oder im Fenster dekorieren.
        `,
        en: ``,
      },
    },
  ],
  video: {
    title: {
      de: 'Mein Live-Auftritt beim ARD-Buffet mit dem Eiskristall',
      en: 'Watch the how to.',
    },
    description: {
      de: `  `,
      en: ``,
    },
    caption: {
      de: `ARD-Buffet Gastauftritt mit 3D Schneeflocke`,
      en: `ARD-Buffet live`,
    },
    videoid: 'IkjM-k1zxdI',
  },

  downloadName: 'schneeflocke-snowflake_papershape.pdf',
  materials: [
    {
      de: '4 DINA4 Papier (bis zu 130g/m²)',
      en: '4 colored papers (8.5in x 11in) (weight: 65lb-110lb)',
    },

    {
      de: 'Bastelkleber oder doppelseitige Klebepads',
      en: 'Glue or double-sided tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Ahle / Falzbein / Buttermesser / defekte Kugelschreibermine',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
  ],
};
