export default {
  url: 'schmetterling-basteln',
  countermark: 'vg07.met.vgwort.de/na/7ba2e983abaf4dac93cf484413805890',
  imageFolder: 'schmetterling',

  name: {
    de: '3D Schmetterling',
    en: '3D Butterfly',
  },
  id: '3-13-blog',
  pdf: {
    price: 6.9,
    priceThirdCountry: 6.9,
    image: {
      filename: {
        de: 'content/schmetterling/schmetterling-basteln-aus-papier.jpg',
        en: 'content/schmetterling/schmetterling-basteln-aus-papier.jpg',
      },
      alt_text: {
        de:
          'DIY Schmetterling basteln mit Papier zur Deko  #papershape #bastelnmitpapier #papercrafts #schmetterling #diygeschenke #frühlingsdekoy',
        en:
          'Learn how to make simple butterfly from paper. Just donwload the template and create your modern wall decor. #papershape #butterfly.',
      },
    },
  },

  meta_title: {
    de: '3D Schmetterling basteln als Wanddeko (mit Vorlage)',
    en: 'How to make a 3d butterfly from paper (with template)',
  },
  meta_description_google: {
    de:
      'Mit dieser Vorlage bastelt ihr euren 3D Schmetterling aus Papier in nur 3 Schritten zu einer modernen Wanddeko.',
    en:
      'Learn how to make a 3d butterfly from paper. Just donwload the template, choose your favorite paper color and start paper crafting.',
  },
  meta_description_social: {
    de:
      'Schmetterlinge basteln aus Papier als Wanddeko in 3 easy Schritten. Mit Vorlage #papershape #schmetterling #diygeschenke #frühlingsdeko',
    ef3n:
      'Learn how to make a 3d butterfly from paper. Just donwload the template and create your modern wall decor. #papershape #butterfly.',
  },
  meta_images_social: [
    {
      de: 'schmetterling-basteln-papershape.jpg',
      en: 'butterfly-craft-wall-art-paper.jpg',
    },
  ],
  image_main: {
    filename: {
      de: 'schmetterling-basteln-aus-papier.jpg',
      en: '3d-butterfly-craft-paper-wall-decor.jpg',
    },
    alt_text: {
      de:
        '3D Schmetterling basteln als coole Wanddeko mit Vorlage / PaperShape #papershape #schmetterling #diygeschenke #frühlingsdeko',
      en:
        'Paper craft your 3d butterflies from paper. cool wall decor / PaperShape #papershape #butterefly ',
    },
  },
  h1: {
    de: '3D Schmetterling basteln als Wanddeko (mit Vorlage)',
    en: 'How to make a 3d butterfly from paper (with template)',
  },
  description: {
    de: `
          
      <p>Schmetterlinge lassen sich in 2d mithilfe einer Vorlage ganz einfach basteln. Meistens sind sie eher klein und zahlreich.</p>
      <p> Mit dieser Papershape Vorlage möchte ich euch zeigen, dass es auch in groß geht und bereits einige wenige 3D Schmetterlinge einen 
      echten Wow-Effekt bei euch und euren Besuchern auslösen werden.</p>
      <p>Diese Papier Schmetterlinge sind zum Aufhängen, Hinlegen, an die Wand kleben oder zum Verschenken. </p>
      <p>Als Gesellschaft passen <a href="/de/blog/libelle-basteln-papier/"> diese Papierlibellen</a> übrigens super zu den schönen Faltern.</p>
      <p>Wer eine weitere und leicht abgewandelte Variante der Schmetterlinge basteln möchte, der schaut einfach mal <a href="/de/product/2-11-sfk/"> hier im Shop vorbei.</a> </p>
    
  
    `,
    en: `
      <p>
     With butterflies I associate liberty, spring feeling and freshness.
      </p>
      <p>
      You may craft butterflies from paper in 2d super easy and in many cases they will be pretty small and lots of them. With this template I wanted to show you how to craft big 3d butterflies. 
      And with just a few of them (like 5 or 6) you will get an amazing result at your home walls.  
      </p>
     <p>If you want to craft a more minimalistic variant of the butterflies, you may want to have quick look <a href="/en/product/2-11-sfk/"> in my shop here .</a></p>
    `,
  },
  inspirationTitle: {
    de: 'Schmetterling basteln als Deko für die Wand oder den Tisch ',
    en: 'You have to try these butterflies',
  },
  inspirations: [
    {
      image: {
        filename: {
          de: 'schmetterling-selbermachen-papier-3d-wanddeko.jpg',
          en: 'butterfly-craft-wall-art-paper.jpg',
        },
        alt_text: {
          de:
            '3D Schmetterling basteln als coole Wanddeko mit Vorlage / PaperShape #papershape #schmetterling #diygeschenke #frühlingsdeko',
          en:
            'Paper craft your 3d butterflies from paper. cool wall decor / PaperShape #papershape #butterefly',
        },
      },
    },
    {
      text: {
        de: `Diese Schmetterlinge sind auch zum Aufhängen und nicht nur zum Hinlegen. Sie haben hinten ein größeres Loch, sodass ihr sie entweder mit einem Klebestreifen an die Wand
        kleben oder sogar an einem Nagel aufhängen könnt. `,
        en: `These butterlfies are adorabel as both table decor and wall decor. You just have to decide where to put them.`,
      },
    },
    {
      image: {
        filename: {
          de: 'schmetterling-selber-machen-3d.jpg',
          en: 'butterflies-papercraft-diy.jpg',
        },
        alt_text: {
          de:
            '3D Schmetterling basteln als coole Wanddeko mit Vorlage / PaperShape #papershape #schmetterling #diygeschenke #frühlingsdeko',
          en:
            'Paper craft your 3d butterflies from paper. cool wall decor / PaperShape #papershape #butterefly',
        },
      },
    },
    {
      text: {},
    },
    {
      image: {
        filename: {
          de: 'butterfly-zoom.jpg',
          en: 'butterfly-zoom.jpg',
        },
        alt_text: {
          de:
            '3D Schmetterling basteln als coole Wanddeko mit Vorlage / PaperShape #papershape #schmetterling #diygeschenke #frühlingsdeko',
          en:
            'Paper craft your 3d butterflies from paper. cool wall decor / PaperShape #papershape #butterefly',
        },
      },
    },
    {
      text: {},
    },
    {
      image: {
        filename: {
          de: '3d-schmetterling-papier-wanddeko-selbermachen.jpg',
          en: 'butterfly-diy-craft-paper-wall-art.jpg',
        },
        alt_text: {
          de:
            '3D Schmetterling basteln als coole Wanddeko mit Vorlage / PaperShape #papershape #schmetterling #diygeschenke #frühlingsdekos',
          en:
            'Paper craft your 3d butterflies from paper. cool wall decor / PaperShape #papershape #butterefly',
        },
      },
    },
    {
      text: {
        de: `Auf einigen grünen Ästen drappiert sind die Schmetterlinge ein echter Hingucker für einen Sonntagsbrunch. Dazu passend vielleicht eine 
        <a href="/de/blog/diy-vase/"> DIY Vase aus Papier mit Blumen.</a> zum Hinstellen.`,
        en: `You may put the butterflies on some green branches and ready is your table decor. It is super easy and additionally you can put this <a href="/en/blog/diy-vase/"> diy vase from paper.</a> on the table.`,
      },
    },
  ],
  stepsBackgroundColor: '',
  stepsTitle: {
    de: "Schmetterlinge aus Tonpapier basteln: so geht's",
    en: 'Tutorial: follow these steps for crafting paper butterflies',
  },
  steps: [
    {
      image: {
        filename: {
          de: 'vorlage.jpg',
          en: 'vorlage.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Schmetterling Vorlage herunterladen',
          en: 'Step: Download the butterfly template',
        },
      },
      text: {
        de: `Die Vorlage für die Schmetterlinge einfach auf ein DIN A4 Tonkarton (160g/m²) in eurer Wunschfarbe ausdrucken.`,
        en: `Print the paper butterfly template (US letter) on paper of your choice.`,
      },
    },
    {
      image: {
        filename: {
          de: 'schneiden.jpg',
          en: 'schneiden.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Ausschneiden',
          en: 'Step: Cutting',
        },
      },
      text: {
        de: `Beginnt mit dem Schneiden. Mit einer Schere einfach alle Elemente entlang der durchgezogenen Linie der Schmetterlinge Vorlage ausschneiden.`,
        en: `Cut out the paper parts (A, B, C, ...) by cutting along the solid lines of the butterfly template.`,
      },
    },
    {
      image: {
        filename: {
          de: 'knicken.jpg',
          en: 'knicken.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Falzen',
          en: 'Step: Folding',
        },
      },
      text: {
        de: `Legt nun ein Lineal an alle gestrichelten Linien (egal wie sie aussehen) an und fahrt mit einer Ahle oder Falzbein die Linien mit Druck ab. Legt ein Papierteil (hier F) so vor euch hin, dass ihr die Zahlen und Buchstaben seht. Knickt die kurz gestrichelten Linien nach innen,
        d.h. zu euch hin oder nach oben (Talfalte). Faltet alle lang gestrichelten Linien nach außen, d.h. von euch weg bzw. nach unten (Bergfalte).`,
        en: `Place a ruler on the dotted lines and mark the folds by pressing down hard and running a bonefolder or an awl along the lines.Start with a paper piece (here F) and place it in front of you. 
        The printed side should be facing you. Subsequently fold all the dotted lines 
        either inwards, which means to fold up (short dotted lines) or outwards, 
        which means to fold down (long dotted lines), according to the markings. 
                    `,
      },
    },
    {
      image: {
        filename: {
          de: 'kleben.jpg',
          en: 'kleben.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Knicken',
          en: 'Step: Folding paper parts',
        },
      },
      text: {
        de: `Bringt auf die Laschen Kleber auf und verklebt jeweils die passenden Zahlenpaare. 
        Ich verwende gerne Klebepads, weil bei flüssigem Kleber einfach zu oft aus Versehen doch 
        ein Klecks über den Rand läuft. Außerdem sind eure Hände klebefrei und ihr müsste euch keinen Kopf machen, dass ihr den Schmetterling von der schönen Seite beschmutzt. Achtet darauf den Kleber nicht direkt an der Kante anzulegen, 
        weil sonst etwas rausschauen oder rauslaufen könnte. Beginnt mit der Nr. 1 auf A und B und bastelt in nummerischer Reihenfolge (2,3,4, etc.) weiter.`,
        en: `Use double-sided tape or any glue onto the unprinted side of 
        the tabs. Each number exists twice. Stick all the same numbers together 
        in ascending order (1, 2, 3, ...). Start with section 1 and stick it to
        the section marked 1 on paper A, pressing firmly enough to make them stick together.`,
      },
    },
    {
      image: {
        filename: {
          de: 'zusammenkleben.jpg',
          en: 'zusammenkleben.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Kleben',
          en: 'Step: stick paper parts together',
        },
      },
      text: {
        de: `Wie ihr seht beginnt ihr mit dem Flügel.`,
        en: `As you can see you start with the wing.`,
      },
    },
    {
      image: {
        filename: {
          de: 'schmetterling-frontal.jpg',
          en: 'schmetterling-frontal.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Kleben',
          en: 'Step: stick paper parts together',
        },
      },
      text: {
        de: `Sobald ihr alle Laschenpaare ganz fest (!) miteinander verklebt habt, solltet ihr dieses Ergebnis erhalten. Es zeigt den Schmetterling von oben..`,
        en: `This is how the butterfly should look like after you glued all flaps together.`,
      },
    },
    {
      image: {
        filename: {
          de: 'schmetterling-rueckseite.jpg',
          en: 'schmetterling-rueckseite.jpg',
        },
        alt_text: {
          de: 'Anleitungsschritt Kleben',
          en: 'Step: stick paper parts together',
        },
      },
      text: {
        de: `Auf der Rückseite des Schmetterlings gibt es eine Aussparung, damit ihr möglichst einfach die letzten Laschen verkleben könnt. Auf den oberen Teil könnt ihr nun einen 
        Tesa kleben oder ein kleines Dreieck einschneiden, um es auf einem Nagel aufzuhängen.`,
        en: `On the back of the butterfly you will find a hole. With this it is much easier for you to glue the last flaps together. On the bottom part of the back  you can either make a hole to hang it on a nail or you use a tape to stick it to the wall.`,
      },
    },
  ],
  downloadName: 'Schmetterling-basteln.pdf',
  materials: [
    {
      de: '3 DINA4 Tonpapier (160g/m²)',
      en:
        '3 cardstock paper uni or with pattern (8.5in x 11in) (weight: 65lb / 176gsm)',
    },
    {
      de: 'Bastelkleber oder doppelseitige Klebepads',
      en: 'Glue or double-sided tape',
    },
    {
      de: 'Schere',
      en: 'Scissors',
    },
    {
      de: 'Lineal',
      en: 'Ruler',
    },
    {
      de: 'Ahle / Falzbein / Buttermesser / defekte Kugelschreibermine',
      en: 'Bonefolder / awl / ball pen refill etc.',
    },
    {
      de: 'ggf. Schneidematte als Unterlage',
      en: 'Cutting mat',
    },
  ],
  pinterest: {
    title: {
      de: 'Speichert die DIY Idee für euer nächstes Projekt auf Pinterest',
      en: 'Pin them!',
    },
    images: [
      {
        filename: {
          de: 'schmetterling-basteln-vorlage-dekoration.jpg',
          en: 'butterfly-craft-paper-wall-art.jpg',
        },
        alt_text: {
          de:
            '3D Schmetterling basteln als coole Wanddeko mit Vorlage / PaperShape #papershape #schmetterling #diygeschenke #frühlingsdekos',
          en:
            'Paper craft your 3d butterflies from paper. cool wall decor / PaperShape #papershape #butterefly',
        },
      },
    ],
  },
};
