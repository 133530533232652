export default {
  id: '2-23-sfk',
  category: [ 'animal_sculpture'],
  name: {
    de: 'Wal',
    en: 'Whale',
  },
  subtitle: {
    de: 'B35 x H11 x T23 cm',
    en: 'W35 x H11 x D23 cm',
  },
  meta_title: {
    de: '3D Wal basteln aus Papier',
    en: 'How to make a paper whale ',
  },
  meta_description_google: {
    de: 'Einen 3D Wal basteln kannst du mit unserer Papercraft Vorlage ganz einfach: jetzt downloaden oder das DIY Set bestellen.',
    en: 'Papercraft whale for sea lovers. Download our papercraft template now.',
  },
  meta_description_social: {
    de: '3D Wal basteln aus Papier als schöne Deko für das Kinderzimmer oder als Wal Laterne für Kinder. Mit unserer Vorlage zum Download gelingt das ganz einfach. #bastelnmitpapier #papercraft #diy #wal #papershape',
    en: 'Papercraft whale decor for sea lovers #papercraft #paperwhale #papershape',
  },
  description: {
    de: `Einen gigantischen Wal basteln in Miniatur: ihr könnt ihn als schöne Deko aufstellen, als Wal Laterne basteln oder zu einer
    Spardose umfunktionieren. Zusammen mit unserem Origami Delfin, Narwal und Seepferdchen könnt ihr eine ganze Unterwasserwelt entstehen lassen. 
    
    Was ist drin im DIY Kit?    
    - 3 bedruckte DINA4 Bögen Tonkarton für die perfekte Stabilität
    - 500 doppelseitig, stark haftende Klebepads für sauberes und schnelles Verkleben
    - Detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den kinderleichten Zusammenbau
   
    Was benötigst du sonst noch?
    Ein wenig Geduld, eine Schere, Lineal und ein Falzwerkzeug (z.B. Ahle, Buttermesser)

    Wir empfehlen das DIY Kit für Kinder ab 14 Jahren.
    
    `,
    en: `What do you get?
    
    -4 printed DINA4 cardboard sheets for the perfect stability (including 2 ducks as shown on the product image)
    -500 strong, double-sided adhesive pads for fast and clean glueing
    -detailed step-by-step instructions in English and German for easy assembling
    
    What do you need at home?
    Start with patience, scissors, ruler, bread knife or a similar tool for creasing

    We recommend our diy kits for children older than 14 years.
    `,
  },
  pdf: {
    description: {
      de: `Was ist drin im Download?
- digitale Vorlage als PDF mit 3 Seiten zum Herunterladen und Ausdrucken - direkt in dein Postfach nach Zahlungseingang
- detaillierte Schritt-für-Schritt-Anleitung in Deutsch und Englisch für den einfachen Zusammenbau


Was benötigst du daheim?
- 4 DIN A4 Seiten Tonkarton oder Tonpapier (mind. 160g/m)
- Schere
- Lineal
- Buttermesser oder ähnliches Falzwerkzeug
- Kleber oder Klebepads
- ein wenig Geduld :)`,

      en: `What do I get with the download?
- digital 3 pages PDF template for downloading and printing - directly into your mail box after payment
- detailed step-by-step-instructions for easy assembling


What do you need at home?
- 4 DIN A4 or US letter colored paper (min. 160g/m²)
- scissors
- ruler
- bread knife or a similar tool for creasing
- glue or adhesive double-sided pads`,
    },
    image: {
      filename: {
        de: 'products/sfkwal/papercraft-wal-basteln.jpg',
        en: 'products/sfkwal/papercraft-wal-basteln.jpg',
      },
      alt_text: {
        de: '3D Papercraft Wal basteln aus Papier #papercraft #bastelnmitpapier #diy #origami',
        en: 'How to make a 3d paper whale #papercraft #paper #diy #paperwhale',
      },
    },
    price: 7.9,
    priceThirdCountry: 7.9,
  },
  choose: {
    image: {
      filename: {
        de: 'products/sfkwal/papercraft-wal-basteln.jpg',
        en: 'products/sfkwal/papercraft-wal-basteln.jpg',
      },
      alt_text: {
        de: '3D Papercraft Wal basteln aus Papier #papercraft #bastelnmitpapier #diy #origami',
        en: 'How to make a 3d paper whale #papercraft #paper #diy #paperwhale',
      },
    },
    colors: {
      color1: {
        de: 'Wal oben',
        en: 'Whale top',
      },
      color2: {
        de: 'Wal unten',
        en: 'Whale bottom',
      },
    },
    disableColors: {
      color2: ['schwarz'],
      color1: ['schwarz'],
    },
    price: 16.9,
    priceThirdCountry: 16.9,
  },
};
