export default {
  id: '2-11-sfk',
  category: [ 'animal_sculpture'],
  name: {
    de: 'Schmetterling',
    en: 'Butterflies',
  },
  subtitle: {
    de: 'B16 x H6 x T13 cm',
    en: 'W16 x H6 x D13 cm',
  },
  meta_title: {
    de: '3D Papier Schmetterlinge selber machen ',
    en: '3d paper butterflies',
  },
  meta_description_google: {
    de: 'Mit diesen 3D Schmetterlingen aus Papier erstrahlt euer Zimmer in neuen Tönen. 100% GelingGarantie✓ Über 10.000 zufriedene Kunden✓',
    en: 'With these 3d paper butterflies your room will look colorful and beautiful. 100% Success Guarantee ✓ More than 10.000 happy customers ✓.',
  },
  meta_description_social: {
    de: '3D Schmetterlinge aus Papier zum selber basteln - DIY Papiertiere für die Wanddeko #papershape',
    en: '3d butterflies from paper - diy paper sculptures for your wall decor #papershape',
  },
  description: {
    de: `KOMPLETTES SET BEINHALTET
    6 Schmetterlinge
     
    a 2 DIN A4 Seiten Fotokarton
    Doppelseitige Klebepads
    Detaillierte schrittweise Anleitung
    
  ANLEITUNG 
    
    In einem kurzen Video erkläre ich euch live, wie ihr in 3 Schritten zu eurem einzigartigen 3D Origami  gelangt. Praktische Tipps inklusive.
    Schneide alle Papierteile mit einer Schere oder einem Cutter aus. Alle durchgezogenen Linien müssen geschnitten werden. Schneide auch dort ein, wo eine kleine Schere angegeben ist.
    Um die Papierteile zu falten, musst du sie vorerst mit einem Lineal und einem Buttermesser oder einer Ahle vorfalzen. Lege dazu das Lineal an die gestrichelten Markierungen an und fahre mit dem Buttermesser und viel Druck am Lineal auf den Linien entlang. Anschließend knickst du die Linien wie in der Anleitung angegeben entweder nach außen oder innen.
    Jetzt gilt es alle Papierteile des 3D Origami miteinander zu verkleben. Verwende dazu die Klebepads, die im Set mitgeliefert sind. Das Verkleben erfolgt in nummerischer Reihenfolge bei 1, 2, 3, etc. Klebe die Pads von außen auf die Lasche und halte einen Sicherheitsabstand von ca. 2mm ein. Beim Verkleben unbedingt auf das Modell von außen schauen. BENÖTIGTE MATERIALIEN (NICHT IM SET ENTHALTEN) : Schere, Lineal, Buttermesser
    ab 14 Jahren empfohlen`,

    en: `    COMPLETE KIT INCLUDES
    6 Butterflies

    a 2 DIN A4 cardboard sheets
    Double-sided adhesive pads
    Detailed step-by-step instructions
    
    REQUIRED MATERIALS (NOT INCLUDED IN SET): scissors, ruler, bread knife

    Age recommendation 14+
    
    STEP BY STEP
    
    Cut the paper parts.
    To fold the paper parts, you need to pre-fold them first with a ruler and a bread knife or an awl. Set the ruler to the dashed line and press with the butter knife along. Then fold the parts as specified in the instructions: either outwardly or inwardly.
    Now glue all the paper parts of the 3D origami together. Use the pads, which are included in the set. The folding takes place in numerical order in 1, 2, 3, etc. Set the adhesive pads on the outer sides of the tabs and keep a safe distance of about 2mm. When gluing necessarily watch the model from the outside.`,
  },

  pdf: {
    description: {
      de: `Schmetterlinge kündigen uns häufig den Frühling an. Doch wenn dieser etwas länger auf sich warten lässt, dann sollten wir sie ganz einfach aus Papier an unserer Wand entlang tanzen lassen. Mit dieser PDF Vorlage könnt ihr euch so viele Schmetterlinge basteln, wie euch lieb ist.
    Gestaltet sie in bunten Farben und Größen, denn ja, ihr könnt sie auch in der Größe variieren, je nachdem wie ihr sie druckt.


♥ DETAILS: ab 14 Jahren

♥ KOMPLETTSET ENTHÄLT

Digitale Vorlage als PDF zum Herunterladen
Detaillierte Schritt-für-Schritt-Anleitung

♥ BENÖTIGTE MATERIALIEN (NICHT IM SET ENTHALTEN) : 2 DIN A4 Seiten Fotokarton oder Tonkarton pro Schmetterling (mind. 160g/m), Schere, Lineal, Buttermesser, Kleber oder Klebepads`,

      en: `Schmetterlinge kündigen uns häufig den Frühling an. Doch wenn dieser etwas länger auf sich warten lässt, 
    dann sollten wir sie ganz einfach aus Papier an unserer Wand entlang tanzen lassen. Mit dieser PDF Vorlage könnt ihr euch so viele Schmetterlinge basteln, 
    wie euch lieb ist.
    Gestaltet sie in bunten Farben und Größen, denn ja, ihr könnt sie auch in der Größe variieren, je nachdem wie ihr sie druckt.


♥ DETAILS: ab 14 Jahren

♥ KOMPLETTSET ENTHÄLT

Digitale Vorlage als PDF zum Herunterladen
Detaillierte Schritt-für-Schritt-Anleitung

♥ BENÖTIGTE MATERIALIEN (NICHT IM SET ENTHALTEN) : 2 DIN A4 Seiten Fotokarton oder Tonkarton pro Schmetterling (mind. 160g/m), Schere, Lineal, Buttermesser, Kleber oder Klebepads`,
    },
    price: 6.9,
    priceThirdCountry: 6.9,
    image: {
      filename: {
        de: 'products/sfkschmetterling/3d-papier-schmetterlinge.jpg',
        en: 'products/sfkschmetterling/3d-papier-schmetterlinge.jpg',
      },
      alt_text: {
        de: '3D Schmetterlinge aus Papier zum selber basteln - DIY Papiertiere für die Wanddeko #papershape',
        en: '3d butterflies from paper - diy paper sculptures for your wall decor #papershape',
      },
    },
  },
  choose: {
    image: {
      filename: {
        de: 'products/sfkschmetterling/3d-schmetterlinge-basteln-papier.jpg',
        en: 'products/sfkschmetterling/3d-schmetterlinge-basteln-papier.jpg',
      },
      alt_text: {
        de: '3D Schmetterlinge aus Papier zum selber basteln - DIY Papiertiere für die Wanddeko #papershape',
        en: '3d butterflies from paper - diy paper sculptures for your wall decor #papershape',
      },
    },
    colors: {
      color1: {
        de: '1.-3. Schmetterling',
        en: '1.-3. Butterflies',
      },
      color2: {
        de: '4.-6. Schmetterlinge',
        en: '4.-6. Butterflies',
      },
    },
    disableColors: {
      color2: ['schwarz', 'tuerkis', 'anthrazit'],
      color1: ['schwarz', 'tuerkis', 'anthrazit'],
    },
    price: 24.9,
    priceThirdCountry: 20.9,
  },
};
