import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { trackViewProduct } from '../../common/customga';
import ColorSelectWithLabel from '../../common/inputs/ColorSelectWithLabel';
import PsProductListByCategory from '../../common/PsProductListByCategory';
import { selectedLang } from '../../data/lang';
import Lang from '../../common/Lang';
import { PsLink } from '../../common/PsLink';
import Currency from '../../common/Currency';
import DeliveryTime from '../../common/DeliveryTime';
import { addProduct } from '../../state/order/orderActions';
import {
  getOrderProductById,
  isFreeShippingCountry,
  getProducts,
  orderFormName,
  getColor1,
  getColor2,
  getSelectedVariantKey,
  isDigitalProductSelected,
} from '../../state/order/orderSelectors';
import PsImage from '../../common/PsImage';
import PsMeta from '../../common/PsMeta';
import AddToBag from './AddToBag';
import InstructionsVideo from './InstructionsVideo';
import ProductMainImage from './ProductMainImage';

class Product extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.product.id !== nextProps.product.id
    ) {
        this.changeOnInvalidVariantOrColor(nextProps);
        this.trackCurrentProduct(nextProps.product);
    }
  }

  changeOnInvalidVariantOrColor(props) {
    if (props.product.choose) {
      if (!props.color1) {
        props.change('color1', 'weiss');
      }
      if (!props.color2) {
        props.change('color2', 'weiss');
      }
    }
    if (props.isDigitalProductSelected && !props.hasDigitalProduct) {
      props.change('kind', 'diyset');
    }
    if (!props.isDigitalProductSelected && !props.hasPhysicalProduct) {
      props.change('kind', 'pdf');
    }
    return null;
  }

  trackCurrentProduct = (product) => {
    if (product.choose) {
      const selectedVariant = product.choose;
      if (selectedVariant == null) {
        return;
      }
      trackViewProduct(product.id, product.name, 'diyset', selectedVariant.price);
    }
    if (product.pdf) {
      const selectedVariant = product.pdf;
      if (selectedVariant == null) {
        return;
      }
      trackViewProduct(product.id, product.name, 'pdf', selectedVariant.price);
    }
  };

  UNSAFE_componentWillMount() {
    this.changeOnInvalidVariantOrColor(this.props);
  }

  componentDidMount() {
    this.trackCurrentProduct(this.props.product, this.props.selectedVariantKey);
  }

  render() {
    let { product } = this.props;
    let variant;
    if (this.props.hasPhysicalProduct) {
      variant = product.choose;
    } else if (this.props.hasDigitalProduct){
      variant = product.pdf;
    }
    return (
      <div>
        <PsMeta
          title={product.meta_title[selectedLang]}
          description={product.meta_description_google[selectedLang]}
          titlefb={
            product.meta_title_social
              ? product.meta_title_social[selectedLang]
              : product.meta_title[selectedLang]
          }
          socialdescription={product.meta_description_social[selectedLang]}
          socialimages={[variant.image.filename[selectedLang]]}
          type="product">
          <meta content={variant.price} property="product:price:amount" />
          <meta content="EUR" property="product:price:currency" />
        </PsMeta>

        <div
          className="container anleitung"
          itemScope
          itemType="https://schema.org/Product">
          <div className="row">
            <div className="col-md-5">
              <ProductMainImage image={variant.image} />
            </div>
            <div className="col-md-offset-1 col-md-6 text-center">
              <h1
                itemProp="name"
                className="product-title"
                style={{
                  marginTop: 60,
                  fontWeight: 'bold',
                  marginBottom: 10,
                }}>
                {product.name[selectedLang]}
              </h1>
              <div style={{ marginBottom: 30 }} className="text-center">
                {product.subtitle[selectedLang]}
              </div>
              <div
                style={{
                  backgroundColor: '#eeeeee',
                  padding: '40px 0',
                }}>
                <div className="row">
                  {this.props.hasDigitalProduct &&
                  this.props.hasPhysicalProduct ? (
                    <div
                      className="col-xs-12"
                      style={{ paddingBottom: '20px' }}>
                      <label className="radio-inline">
                        <Field
                          name="kind"
                          component="input"
                          type="radio"
                          value="diyset"
                        />{' '}
                        DIY-Set
                      </label>
                      <label className="radio-inline">
                        <Field
                          name="kind"
                          component="input"
                          type="radio"
                          value="pdf"
                        />{' '}
                        Digitale Vorlage
                      </label>
                    </div>
                  ) : this.props.hasDigitalProduct ? (
                    <h3>Digitale Vorlage</h3>
                  ) : this.props.hasPhysicalProduct ? (
                    <h3>DIY-Set</h3>
                  ) : null}
                  <div className="col-xs-10 col-xs-offset-1">
                    {this.props.isDigitalProductSelected && this.props.hasDigitalProduct ? (
                      <div
                        style={{
                          backgroundColor: 'white',
                          padding: 25,
                        }}>
                        <div
                          style={{
                            fontSize: 26,
                            fontFamily: '"Montserrat"',
                            letterSpacing: 2,
                          }}
                          itemProp="offers"
                          itemScope
                          itemType="http://schema.org/Offer">
                          <Currency value={product.pdf.price} />
                        </div>
                        <div style={{ lineHeight: 1 }}>
                          <small
                            style={{
                              fontWeight: 'normal',
                            }}>
                            <Lang de="inkl. MwSt." en="incl. VAT" />
                          </small>
                        </div>
                        <div className="downloadbox" style={{ minWidth: 240 }}>
                          <button
                            type="submit"
                            id="btnJetztKaufen"
                            className="btn btn-primary btn-lg btn-block jetzt-kaufen"
                            onClick={() => buyNow(this.props, this.state)}>
                            <Lang de="In den Warenkorb" en="Add to bag" />
                          </button>
                        </div>
                      </div>
                    ) : this.props.hasPhysicalProduct ? (
                      <div
                        style={{
                          backgroundColor: 'white',
                          padding: 25,
                        }}>
                        {variant.old_price && (
                          <div
                            style={{
                              fontSize: 18,
                              fontFamily: '"Montserrat"',
                              letterSpacing: 2,
                              color: 'grey',
                              textDecoration: 'line-through',
                            }}>
                            <Currency value={variant.old_price} />
                          </div>
                        )}

                        <div
                          style={{
                            fontSize: 26,
                            fontFamily: '"Montserrat"',
                            letterSpacing: 2,
                          }}
                          itemProp="offers"
                          itemScope
                          itemType="http://schema.org/Offer">
                          <Currency value={variant.price} />
                        </div>
                        <div style={{ lineHeight: 1 }}>
                          <small
                            style={{
                              fontWeight: 'normal',
                            }}>
                            <Lang de="inkl. MwSt." en="incl. VAT" />
                          </small>
                        </div>
                        <p
                          className="small"
                          style={{
                            margin: '5px 0px',
                            fontWeight: 'normal',
                          }}>
                          <Lang de="zzgl. " en="excl. " />

                          <PsLink
                            to="/termsconditions"
                            style={{
                              padding: '0 0 3px',
                              fontSize: 15,
                            }}>
                            <Lang de="Versandkosten" en="shipping costs" />
                          </PsLink>
                        </p>
                        <div
                          id="anzahlBestand"
                          style={{
                            fontSize: 12,
                            color: '#c50000',
                            paddingTop: 5,
                          }}
                        />
                        <div className="form-horizontal">
                          {product.choose &&
                            Object.keys(product.choose.colors).map(colorKey => (
                              <Field
                                key={colorKey}
                                name={colorKey}
                                label={
                                  product.choose.colors[colorKey][
                                    selectedLang
                                  ] + ': '
                                }
                                disableColors={
                                  product.choose.disableColors &&
                                  product.choose.disableColors[colorKey]
                                }
                                component={ColorSelectWithLabel}
                              />
                            ))}
                        </div>
                        <div className="downloadbox" style={{ minWidth: 240 }}>
                          <button
                            type="submit"
                            id="btnJetztKaufen"
                            className="btn btn-primary btn-lg btn-block jetzt-kaufen"
                            onClick={() =>
                              buyNow(this.props, this.state)}>
                            <Lang de="In den Warenkorb" en="Add to bag" />
                          </button>
                          <p className="small">
                            <DeliveryTime />
                          </p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {!this.props.isDigitalProductSelected && (
                    <div className="col-xs-12 col-xs-offset-0 col-sm-8 col-sm-offset-2 col-md-9 col-md-offset-2">
                      <ul className="vorteile">
                        <li>
                          <Lang
                            de="100%-Geling-Garantie"
                            en="60 days money-back guarantee"
                          />
                        </li>
                        <li>
                          <Lang
                            de="Geld-zurück bei Nichtgelingen"
                            en="Money back guarantee if you are unsatisfied"
                          />
                        </li>
                        {this.props.freeShippingCountry && (
                          <li>
                            <Lang
                              de="Gratis Versand ab 45 Euro"
                              en="free shipping for orders over 45 Euro"
                            />
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              {/*
        <div style="margin-top: 5px; width:180px;">
            <small>Du möchtest ein fertiges PaperShape? <a href="/de/kontakt?nachricht=Hallo%20Anastasia%2C%20ich%20w%C3%BCrde%20gerne%20das%20PaperShape%20Hirsch%20vollst%C3%A4ndig%20zusammengebaut%20erwerben.%20Was%20w%C3%BCrde%20das%20kosten%20und%20wie%20lange%20ist%20die%20Lieferzeit%3F">Dann schreib mir!</a></small>
        </div>
        */}
            </div>
          </div>

          <div className="row" style={{ marginTop: 60 }}>
            <div className="col-xs-12">
              <h2 style={{ margin: '0 0 20px' }}>
                <Lang de="Produktbeschreibung" en="Product description" />
              </h2>
              <p
                // style={{ columnCount: 2 }}
                itemProp="description"
                dangerouslySetInnerHTML={{
                  __html: (this.props.isDigitalProductSelected &&
                  product.pdf &&
                  product.pdf.description
                    ? product.pdf.description[selectedLang]
                    : product.description ? product.description[selectedLang] : ''
                  ).replace(/(?:\r\n|\r|\n)/g, '<br />'),
                }}
              />
            </div>

            {product.image_mood && (
              <div className="col-sm-6 col-md-offset-0 col-lg-6">
                <PsImage image={product.image_mood} />
              </div>
            )}
          </div>

          {product.colorinspirations && (
            <div className="row">
              {product.colorinspirations.map((image, idx) => (
                <div key={idx} className="col-sm-3">
                  <PsImage image={image.image} />
                  <p style={{ textAlign: 'center', fontSize: '11px' }}>
                    {image.caption[selectedLang]}
                  </p>
                </div>
              ))}
            </div>
          )}

          {product.image_packaging && (
            <div className="row" style={{ margin: 40 }}>
              <div className="col-xs-12">
                <PsImage image={product.image_packaging} />
              </div>
            </div>
          )}
        </div>

        <InstructionsVideo videoid={'pMwVnZ7aJcc'} />

        <AddToBag onClick={() => buyNow(this.props, this.state)} />

        <div>
          <PsProductListByCategory open />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const products = getProducts(state);
  const product = getOrderProductById(products, props.match.params.id);
  const hasDigitalProduct = product.pdf != null;
  const hasPhysicalProduct = product.choose != null;
  return {
    freeShippingCountry: isFreeShippingCountry(state),
    product: product,
    color1: getColor1(state),
    color2: getColor2(state),
    selectedVariantKey: getSelectedVariantKey(state),
    hasDigitalProduct,
    hasPhysicalProduct,
    isDigitalProductSelected: isDigitalProductSelected(state),
    initialValues: {
      color1: 'weiss',
      color2: 'weiss',
      kind: hasDigitalProduct && !hasPhysicalProduct ? 'pdf' : 'diyset',
      billingAddress: { country: 'de' },
      shippingAddress: { country: 'de' },
    },
  };
};

const Connected = connect(mapStateToProps)(Product);

export default reduxForm({
  form: orderFormName,
  destroyOnUnmount: false,
})(Connected);

function buyNow(props, state) {
  const { product, isDigitalProductSelected } = props;
  props.dispatch(
    addProduct(
      props.product.id,
      product.choose &&
        product.choose.colors &&
        product.choose.colors.color1 &&
        props.color1,
      product.choose &&
        product.choose.colors &&
        product.choose.colors.color2 &&
        props.color2,
      isDigitalProductSelected
    )
  );
  props.history.push('/' + props.match.params.lang + '/cart/');
}
